import { NgModule } from '@angular/core';
import { ExtraOptions, RouterModule, Routes } from '@angular/router';

import { AuthGuard } from '@gipisistemas/ng-core';

const routes: Routes = [
    {
        path: 'dashboard',
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'financial',
        loadChildren: () => import('./financial/financial.module').then(m => m.FinancialModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'financial/registers',
        loadChildren: () => import('./registration/registration.module').then(m => m.RegistrationModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'financial/sales',
        loadChildren: () => import('./sale/sale.module').then(m => m.SaleModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'financial/notifications',
        loadChildren: () => import('./notification/notification.module').then(m => m.NotificationModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'global',
        loadChildren: () => import('./global/global.module').then(m => m.GlobalModule),
        canActivate: [AuthGuard],
    },
    {
        path: 'errors',
        loadChildren: () => import('./errors/errors.module').then(m => m.ErrorsModule)
    },
    {
        path: 'oauth',
        loadChildren: () => import('./oauth/oauth.module').then(m => m.OauthModule),
    },
    {
        path: '',
        pathMatch: 'full',
        redirectTo: 'dashboard',
    },
    {
        path: '**',
        pathMatch: 'full',
        redirectTo: 'errors/404'
    }
];

const config: ExtraOptions = {
    scrollPositionRestoration: 'enabled',
    onSameUrlNavigation: 'reload',
    initialNavigation: true,
    useHash: true
};

@NgModule({
    imports: [RouterModule.forRoot(routes, config)],
    exports: [RouterModule]
})
export class AppRoutingModule { }
