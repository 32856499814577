import * as tslib_1 from "tslib";
import { AfterViewInit, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, of } from 'rxjs';
import { filter, map, takeLast } from 'rxjs/operators';
import { environment } from '@gipi-environment/environment';
import { GeneralIcons, MenuIcons, NewIcons } from '@gipi-assets/assets.enum';
import { FinancialCompanyService } from '@gipi-financial/company/services/company.service';
import { ConfigurationDialogComponent } from '@gipi-financial/configuration/components/configuration-dialog/configuration-dialog.component';
import { FinancialConfigurationService } from '@gipi-financial/configuration/services/configuration.service';
import { OAuthUserService } from '@gipi-financial/user/services/user.service';
import { GlobalFileService } from '@gipi-global/file/services/file.service';
import { CustomerNotificationConfigDialogComponent } from '@gipi-notification/customer-notification-config/components/customer-notification-config-dialog/customer-notification-config-dialog.component';
import { NotificationInvoiceIntegrationsService } from '@gipi-notification/invoice-integrations/services/invoice-integrations.service';
import { GIPINoveltiesDialogComponent } from '@gipi-shared/components/novelties-dialog/novelties-dialog.component';
import { UserInformationDialogComponent } from '@gipi-shared/components/user-information-dialog/user-information-dialog.component';
import { CustomAuthenticationService } from '@gipi-shared/services/custom-authentication.service';
import { SessionStorageService } from '@gipi-shared/services/session-storage.service';
import { ArrayUtil, DialogService, GIPIAbstractComponent, GIPIBaseService, GIPISortModel, NavService, ObjectUtil, StringUtil, SvgRegisterService } from '@gipisistemas/ng-core';
export class AppComponent extends GIPIAbstractComponent {
    constructor(baseService, activatedRoute, _router, _customAuthenticationService, _userService, _companyService, _configurationService, _invoiceIntegrationsService, _dialogService, _navService, _svgRegisterService, _fileService, _sessionStorageService) {
        super(baseService, activatedRoute);
        this.baseService = baseService;
        this.activatedRoute = activatedRoute;
        this._router = _router;
        this._customAuthenticationService = _customAuthenticationService;
        this._userService = _userService;
        this._companyService = _companyService;
        this._configurationService = _configurationService;
        this._invoiceIntegrationsService = _invoiceIntegrationsService;
        this._dialogService = _dialogService;
        this._navService = _navService;
        this._svgRegisterService = _svgRegisterService;
        this._fileService = _fileService;
        this._sessionStorageService = _sessionStorageService;
        this.companyList = [];
        this.company = null;
        this.userPhotoSrc = '';
        this.hasNotificationSub$ = new BehaviorSubject(false);
        this.widgetChat = false;
        this.companyFn = (obj) => !ObjectUtil.isNull(obj) ? obj.name : '';
        this._loadScriptHotjar();
    }
    get isAuthenticated$() {
        const token = this._customAuthenticationService.token;
        if (ObjectUtil.isNull(token)) {
            this._router.events
                .pipe(takeLast(1), filter(event => (event instanceof NavigationEnd) && !event.url.includes('/oauth'))).subscribe((event) => {
                const urlFinal = event['urlAfterRedirects'];
                const isOAuthRoute = !StringUtil.isEmpty(urlFinal) && urlFinal.toLowerCase().includes('/oauth');
                if (!isOAuthRoute) {
                    this._router.navigate(['/oauth/login']);
                }
            });
            // const isOAuthRoute: boolean = this._router.url.includes('/oauth');
            // if (!isOAuthRoute) {
            //     this._router.navigate(['/oauth/login']);
            // }
            return of(false);
        }
        return of(!ObjectUtil.isNull(token) && this._customAuthenticationService.isValidToken(token));
    }
    get firstName() {
        const token = this._customAuthenticationService.token;
        if (ObjectUtil.isNull(token)) {
            return StringUtil.EMPTY;
        }
        const nameParts = token.user.name.split(' ');
        const firstName = ((nameParts.length > 1) && (nameParts[0].length <= 2)) ? `${nameParts[0]} ${nameParts[1]}` : nameParts[0];
        return (`Olá, ${firstName}` || StringUtil.EMPTY);
    }
    get username() {
        const token = this._customAuthenticationService.token;
        if (ObjectUtil.isNull(token)) {
            return StringUtil.EMPTY;
        }
        return (token.user.name || StringUtil.EMPTY);
    }
    get email() {
        const token = this._customAuthenticationService.token;
        if (ObjectUtil.isNull(token)) {
            return StringUtil.EMPTY;
        }
        return (token.user.username || StringUtil.EMPTY);
    }
    get lastAccess() {
        const token = this._customAuthenticationService.token;
        if (ObjectUtil.isNull(token)) {
            return null;
        }
        return (token.user.lastAccess || null);
    }
    get menuList$() {
        return this._customAuthenticationService.token$.pipe(map(token => !ObjectUtil.isNull(token) ? this._filterMenuItems(token.user.menuList) : []));
    }
    get hasNovelties() {
        // ["Domingo", "Segunda-Feira", "Terça-Feira", "Quarta-Feira", "Quinta-Feira", "Sexta-Feira", "Sábado"]
        var date = new Date();
        var weekday = date.getDay();
        return ((weekday) && (weekday === 3));
    }
    get hasNotification$() {
        return !ObjectUtil.isNull(this.hasNotificationSub$)
            ? this.hasNotificationSub$.asObservable()
            : of(false);
    }
    get isCompanyGIPI$() {
        return of(this._isCompanyGIPI);
    }
    get _isCompanyGIPI() {
        const currentCnpjCompany = (!ObjectUtil.isNull(this.company) ? this.company.cpfOrCnpj : null);
        const cnpjCompanyGIPI = environment.cnpjCompany || null;
        return (!StringUtil.isEmpty(cnpjCompanyGIPI) &&
            !StringUtil.isEmpty(currentCnpjCompany) &&
            (cnpjCompanyGIPI === currentCnpjCompany));
    }
    ngOnInit() {
        super.ngOnInit();
        this._registerSvgs();
        this.isAuthenticated$.subscribe(isAuth => {
            if (isAuth) {
                const token = this._customAuthenticationService.token;
                if (!ObjectUtil.isNull(token) && this._customAuthenticationService.isValidToken(token)) {
                    this._getUserPhoto();
                    this.getNotifications();
                    this._getCompany();
                    this._setConfigurationInLocalStorage();
                }
            }
        });
        // this._customAuthenticationService.token$.subscribe((token: TokenDTO<OAuthUser>) => {
        //     if (!ObjectUtil.isNull(token) && this._customAuthenticationService.isValidToken(token)) {
        //         this._getUserPhoto();
        //         this.getNotifications();
        //         this._getCompany();
        //         this._setConfigurationInLocalStorage();
        //     }
        // });
    }
    ngAfterViewInit() {
        super.ngAfterViewInit();
        setTimeout(_ => this._showWidgetChat(), 1000);
    }
    ngOnDestroy() {
        super.ngOnDestroy();
        this.hasNotificationSub$.next(false);
        this.hasNotificationSub$.complete();
    }
    getNotifications() {
        if (!this._isCompanyGIPI) {
            this.hasNotificationSub$ = new BehaviorSubject(false);
            return;
        }
        if (ObjectUtil.isNull(this.hasNotificationSub$) || (!ObjectUtil.isNull(this.hasNotificationSub$) && this.hasNotificationSub$.closed)) {
            this.hasNotificationSub$ = new BehaviorSubject(false);
        }
        this._invoiceIntegrationsService.findIfHaveNotificationToReceive().subscribe(hasNotification => {
            this.hasNotificationSub$.next(hasNotification);
        }, error => {
            this.hasNotificationSub$.next(false);
            this.addErrorMessage(error);
            this.loading = false;
        });
    }
    toggleSidenav() {
        this._navService.toggle();
    }
    isOpened() {
        return this._navService.isOpened;
    }
    changeCompany(company) {
        try {
            this.loading = true;
            this._customAuthenticationService.changeCompany(company.id).toPromise().then(_ => {
                location.replace('/');
                this.loading = false;
            }).catch(error => {
                this.loading = false;
                this.handleError(error);
            });
        }
        catch (e) {
            this.loading = false;
            this.addErrorMessage(e);
        }
    }
    logout() {
        this._customAuthenticationService.logout();
        this.clearSessionStorage();
    }
    openNoveltiesDialog() {
        this._dialogService.open({
            componentOrTemplateRef: GIPINoveltiesDialogComponent,
            width: '55%',
            height: '90%',
            panelClass: 'no-scrolls-dialog'
        });
    }
    openUserInformationDialog() {
        return tslib_1.__awaiter(this, void 0, void 0, function* () {
            try {
                const token = this._customAuthenticationService.token;
                if (ObjectUtil.isNull(token)) {
                    this.addErrorMessage('Ocorreu um erro ao obter as credências para alteração do usuário');
                    return;
                }
                const userLogged = yield this._userService.getByUserName(token.user.username).toPromise().catch(error => {
                    this.addErrorMessage(error);
                    return null;
                });
                if (ObjectUtil.isNull(userLogged)) {
                    this.addErrorMessage('Ocorreu um erro ao obter as credências para alteração do usuário');
                    return;
                }
                this._dialogService.open({
                    componentOrTemplateRef: UserInformationDialogComponent,
                    data: ObjectUtil.clone(userLogged),
                    width: '50%'
                }).afterClosed().toPromise().then((user) => {
                    if (!ObjectUtil.isNewModel(user)) {
                        userLogged.name = user.name;
                        userLogged.photo = user.photo;
                        this._getUserPhoto();
                    }
                });
            }
            catch (e) {
                this.loading = false;
                this.addErrorMessage(e);
            }
        });
    }
    clearSessionStorage() {
        sessionStorage.clear();
    }
    onMenuItemIntegrationChange() {
        this._dialogService.open({
            componentOrTemplateRef: CustomerNotificationConfigDialogComponent,
            data: null,
            width: '60%',
            height: '65rem'
        });
    }
    onMenuItemConfigurationChange() {
        this._dialogService.open({
            componentOrTemplateRef: ConfigurationDialogComponent,
            data: null,
            width: '60%',
            height: '65rem'
        });
    }
    _loadScriptHotjar() {
        const hotjarId = environment.hotjarId;
        if (environment.production && (hotjarId > 0)) {
            let scriptElement = document.createElement('script');
            scriptElement.textContent = `
            (function(h,o,t,j,a,r){
                h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
                h._hjSettings={hjid:${hotjarId},hjsv:6};
                a=o.getElementsByTagName('head')[0];
                r=o.createElement('script');r.async=1;
                r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
                a.appendChild(r);
            })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
            `;
            document.getElementsByTagName('head')[0].appendChild(scriptElement);
        }
    }
    _showWidgetChat() {
        const btnWidgetChat = document.getElementById('widgetChat');
        if (!ObjectUtil.isNull(btnWidgetChat)) {
            btnWidgetChat.style.display = 'flex';
        }
    }
    _getCompany() {
        const token = this._customAuthenticationService.token;
        if (ObjectUtil.isNull(token)) {
            return;
        }
        let count = 1;
        this._companyService.findByValue('', 0, 100, new GIPISortModel('person.name', 'ASC')).subscribe(page => {
            if (!ObjectUtil.isNull(page) && !ArrayUtil.isEmpty(page.content)) {
                if ((token.user.companyList.length !== page.content.length) && count <= 2) {
                    this._getCompany();
                    count++;
                }
                else {
                    this.companyList = ArrayUtil.clone(page.content);
                    const currentCompany = page.content.find(company => company.id === token.user.currentCompany);
                    this.company = ObjectUtil.clone(currentCompany);
                }
            }
        }, error => {
            this.loading = false;
            this.addErrorMessage(error);
        });
    }
    _registerSvgs() {
        this._svgRegisterService.registerListSvgIcons(GeneralIcons, '../assets/images/icons');
        this._svgRegisterService.registerListSvgIcons(MenuIcons, '../assets/images/menu');
        this._svgRegisterService.registerListSvgIcons(NewIcons, '../assets/images/new-icons');
    }
    _filterMenuItems(menuList) {
        if (!environment.production) {
            return menuList;
        }
        const hideMenuList = [
            '/financial/registers/products/products',
            '/financial/registers/promotional-schedules/promotional-scheduling',
        ];
        if (!this._isCompanyGIPI) {
            hideMenuList.push('/financial/notifications/invoice-integration');
        }
        return menuList.reduce((filtered, item) => {
            if (!hideMenuList.includes(item.route)) {
                if (item.menuList && item.menuList.length > 0) {
                    item.menuList = this._filterMenuItems(item.menuList);
                }
                filtered.push(item);
            }
            return filtered;
        }, []);
    }
    _getUserPhoto() {
        try {
            const token = this._customAuthenticationService.token;
            if (ObjectUtil.isNull(token)) {
                return;
            }
            if (!StringUtil.isEmpty(token.user.photo)) {
                this._fileService.download('authenticator', token.user.photo).subscribe(resp => {
                    const fileReader = new FileReader();
                    fileReader.readAsDataURL(resp.body);
                    fileReader.onloadend = () => this.userPhotoSrc = fileReader.result;
                    this.loading = false;
                }, error => {
                    throw new Error(error);
                });
            }
        }
        catch (e) {
            this.loading = false;
            this.addErrorMessage(e);
        }
    }
    _setConfigurationInLocalStorage() {
        this._configurationService.findEnabled().subscribe(configuration => this._sessionStorageService.set('configuration', configuration));
    }
}
