import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { RegistrationPaths } from '@gipi-paths/registration.paths';
import { GIPIBaseAbstractFilterModel } from '@gipi-shared/models/base-abstract-filter.model';
import { GIPIAbstractCrudService, GIPIBaseService } from '@gipisistemas/ng-core';
import { RegistrationConnectConfig } from '../models/connect-config.model';

@Injectable({ providedIn: 'root' })
export class RegistrationConnectConfigService extends GIPIAbstractCrudService<RegistrationConnectConfig, GIPIBaseAbstractFilterModel> {

    /** Se trocar as permissões enquanto estiver na tela que usa a twilio */
    public updated$: BehaviorSubject<boolean> = new BehaviorSubject(false);

    constructor(protected baseService: GIPIBaseService) {
        super(RegistrationPaths.connectConfig, 'v1', baseService);
    }

}
